import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 250,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4)
  },
  image: {
    marginBottom: theme.spacing(5)
  },
  subtitle: {
    textAlign: 'center',
    wordWrap: 'break-word'
  }
}));

export default function IconDescription(props) {
  const {icon, description, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
          <img src={icon} alt='' className={classes.image}/>
          <Typography variant={'subtitle1'} className={classes.subtitle}>
            {description}
          </Typography>
    </div>
  );
}
