import React from 'react';
import Box from '@material-ui/core/Box';
import SEO from "../components/utility/SEO";
import GridContainer from "../components/GridContainer";
import IconDescription from "../components/IconDescription";
import AboutTopSection from "../components/AboutTopSection";
import Typography from "@material-ui/core/Typography";
import PersonCard from "../components/PersonCard";
import {graphql} from "gatsby";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function About({data}) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicAboutUsPage) return '';
  const { data: pageData } = data.prismicAboutUsPage;

  return (
    <>
      <SEO title="About"/>
      <Box>
        <AboutTopSection
          image={pageData.image ? pageData.image.url : null}
          title={pageData.title}
          subtitle={pageData.subtitle}
        />
        <GridContainer gridItemProps={{xs: 12, md: 4}}>
          <Typography variant={'h3'}>{pageData.feature_cards_title}</Typography>
        </GridContainer>
        <GridContainer gridItemProps={{xs: 12, md: 4}}
                       style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
          {pageData.feature_cards && pageData.feature_cards.map((iconDescription, index) => {
            return (
                <IconDescription icon={iconDescription.icon ? iconDescription.icon.url : null}
                                 description={iconDescription.description} key={index}/>
            )
          })}
        </GridContainer>
        <GridContainer gridItemProps={{xs: 12, md: 4}}>
          <Typography variant={'h3'}>{pageData.person_title}</Typography>
        </GridContainer>
        <GridContainer gridItemProps={{xs: 12, md: 4}}>
          {pageData.persons && pageData.persons.map((person, index) => {
            if (!person || !person.person ||
                !person.person.document || !person.person.document.data) return '';
            return (
              <PersonCard
                image={person.person.document.data.image ? person.person.document.data.image.url : null}
                name={person.person.document.data.name}
                description={person.person.document.data.description}
                social={person.person.document.data.social}
                style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
                key={index}/>
            )
          })}
        </GridContainer>
      </Box>
    </>
  );
}

export const aboutUsPageQuery = graphql`
    query AboutUsPage($id: String!) {
        prismicAboutUsPage(id: {eq: $id}) {
            data {
                meta_title
                title
                subtitle
                image {
                    url
                }
                feature_cards_title
                feature_cards {
                    description
                    icon {
                        url
                    }
                }
                person_title
                persons {
                    person {
                        document {
                            ... on PrismicPerson {
                                data {
                                    name
                                    description
                                    image {
                                        url
                                    }
                                    social {
                                        type
                                        url {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
