import React from 'react';
import Grid from "@material-ui/core/Grid";

export default function GridContainer(props) {
  const {children, gridItemProps, ...rest} = props;

  return (
      <Grid container alignItems={'stretch'} justify={'space-evenly'} {...rest}>
        {children && Array.isArray(children)
            ? children.map((child, index) => {
              return (
                  <Grid item container xs={4} justify={'center'} key={index} {...gridItemProps}>
                    {child}
                  </Grid>
              )
            })
            :
            <Grid item container xs={4} justify={'center'} {...gridItemProps}>
              {children}
            </Grid>
        }
      </Grid>
  );
}
