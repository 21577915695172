import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {headerHeight} from "./Layout";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${headerHeight}px)`,
  },
  rootMobile: {
    marginBottom: theme.spacing(8)
  },
  gridItem: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(8)
  },
  gridItemMobile: {
    padding: theme.spacing(3)
  },
  image: {
    objectFit: 'contain',
    objectPosition: 'right',
    width: '100%',
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(4),
    maxWidth: 500,
  },
  subtitle: {
    maxWidth: 500
  },
  subtitleMobile: {
    maxWidth: 500,
    textAlign: 'center'
  },
  buttonLabel: {
    color: theme.palette.dark
  },
  uppercase: {
    textTransform: 'uppercase'
  }
}));

export default function AboutTopSection(props) {
  const {title, subtitle, button, url, image, reverse = false, ...rest} = props;

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction={reverse ? 'row' : 'row-reverse'} alignItems='stretch'
          justify={!matches ? "space-between" : 'center'} className={!matches ? classes.root : classes.rootMobile} {...rest}>
      <Hidden smDown initialWidth={'lg'}>
        <Grid item md={6} component='img' src={image} alt='' className={classes.image}/>
      </Hidden>
      <Hidden mdUp initialWidth={'lg'}>
        {title &&
        <Typography className={!matches ? classes.title : clsx(classes.title, classes.uppercase)} variant={'h1'}>{title}</Typography>
        }
      </Hidden>
      <Grid item container xs={12} md={6} direction='column' alignItems={!matches ? 'flex-start' : 'center'} justify="center"
            className={!matches ? classes.gridItem : classes.gridItemMobile}>
        <Hidden smDown initialWidth={'lg'}>
          {title &&
          <Typography className={!matches ? classes.title : clsx(classes.title, classes.uppercase)} variant={'h1'}>{title}</Typography>
          }
        </Hidden>
        {subtitle &&
        <Typography className={!matches ? classes.subtitle : classes.subtitleMobile} variant={'subtitle1'}>{subtitle}</Typography>
        }
      </Grid>
      <Hidden mdUp initialWidth={'lg'}>
        <Grid item xs={12} component='img' src={image} alt='' className={classes.image}/>
      </Hidden>
    </Grid>
  );
}
