import React from 'react';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import GridContainer from "./GridContainer";
import GatsbyLink from "./utility/GatsbyLink";
import {Facebook, Instagram, LinkedIn, Twitter} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  card: {
    width: theme.spacing(30),
    borderRadius: 4
  },
  media: {
    height: theme.spacing(30),
    width: theme.spacing(30),
    borderRadius: 4,
  },
  nameTypography: {
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    color: theme.palette.black
  },
  descriptionTypography: {
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(1),
    color: theme.palette.black
  }
}));

export default function PersonCard(props) {
  const {image, name, description, social, ...rest} = props;

  const classes = useStyles();

  function socialComponents() {
    if (social) {
      return social.map((socialObject, index) => {
        switch (socialObject.type) {
          case 'facebook':
            return <GatsbyLink url={socialObject.url.url} key={index}>
              <Facebook fontSize="small" color="primary"/>
            </GatsbyLink>;
          case 'instagram':
            return <GatsbyLink url={socialObject.url.url} key={index}>
              <Instagram fontSize="small" color="primary"/>
            </GatsbyLink>;
          case 'twitter':
            return <GatsbyLink url={socialObject.url.url} key={index}>
              <Twitter fontSize="small" color="primary"/>
            </GatsbyLink>;
          case 'linkedin':
            return <GatsbyLink url={socialObject.url.url} key={index}>
              <LinkedIn fontSize="small" color="primary"/>
            </GatsbyLink>;
          default:
            return ''
        }
      })
    }
    return ''
  }

  return (
      <Card elevation={0} className={classes.card} {...rest}>
        <CardMedia
            className={classes.media}
            image={image}
            title=''
        />
        <CardContent>
          <Typography className={classes.nameTypography} variant="h5">
            {name}
          </Typography>
          {description && description.split('\n').flatMap((text, index) => {
            return (
                <Typography variant={'subtitle2'} key={index} className={classes.descriptionTypography}>
                  {text}
                </Typography>
            )
          })}
          <GridContainer gridItemProps={{xs: 3}}>
            {socialComponents()}
          </GridContainer>
        </CardContent>
      </Card>
  );
}
